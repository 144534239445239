.document {
  border: 1px solid rgba(18, 18, 18, 0.05);
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  border-radius: 18px;
  padding: 24px 19px;
  &__title {
    img {
      padding-right: 8px;
    }
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #020202;
    display: flex;
    align-items: center;
  }
  &__open-link {
    padding-top: 12px;
    a {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 124.18%;
      display: flex;
      align-items: center;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      color: var(--color-button-background-dark);
      text-decoration: none;
      img {
      }
    }
  }
}

.documents {
  border-top: 1px solid #eef1f3;
  &__block-title {
    margin-top: 73px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
  &__block-doc {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 40px;
  }
  &__block-calc {
    margin: 70px 0;
  }
}

@media (max-width: 992px) {
  .documents {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
    &__block-title {
      margin-top: 32px;
    }
    &__block-doc {
      margin-top: 24px;
    }
    &__block-doc {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
    }
  }
}
