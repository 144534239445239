.traffics-sms {
  .container-mobile {
    display: none !important;
    background-color: black;
  }
}
.pad {
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-direction: column;
    background-color: beige;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    img {
      margin-top: 20px;
      height: 90px;
      text-align: center;
    }
  }
  &__text-title {
    margin-top: 20px;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 124.18%;
    display: flex;
    align-items: center;
    color: #020202;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(18, 18, 18, 0.05);
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.25);
    border-radius: 18px;
    padding: 32px;
    margin-bottom: 20px;
  }
  .button__dark-theme {
    padding: 20px 50px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    transition: 0.3s;
  }
  .button__dark-theme:hover {
    opacity: 0.9;
  }
  &__advantage {
    max-width: 980px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    &__block {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      &__img {
        background: var(--color-button-background-dark);
        margin-top: 6px;
        padding: 10px;
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        fill: #fff;
        border-radius: 5px;
        margin-right: 10px;
        text-align: center;
      }
      &__text {
        &__title {
          padding-top: 10px;
          padding-bottom: 5px;
          font-size: 16px;
          font-weight: 700;
          color: #333;
        }
        &__desc {
          color: #666;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .pad {
    &__advantage {
      padding-left: 16px;
      padding-right: 16px;
      max-width: 980px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      &__block {
        &__text {
          &__title {
            padding-top: 5px;
          }
          &__desc {
            font-size: 13px;
          }
        }
      }
    }
    &__text-title {
      margin-left: 16px;
      margin-right: 16px;
      font-size: 18px;
    }
    padding-bottom: 20px;
  }
}
