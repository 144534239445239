.text-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 124.18%;
  display: flex;
  align-items: center;
  color: var(--color-dark-text);
  // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
}

@media (max-width: 996px) {
  .text-title {
    font-size: 22px;
  }
}
