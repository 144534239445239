.footer {
  &__text-block {
    padding-bottom: 64px;
  }
  &__border {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
  }
  //   background-color: white;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 32px 0;
    &__image {
    }
    &__menu {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      div {
        padding: 0 12px;
        color: var(--color-dark-text);
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }
  &__about {
    padding-top: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    &__card {
      display: flex;
      flex-direction: column;
      img {
        width: 40px;
      }
      span {
        padding-top: 8px;
        color: var(--color-dark-text);
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      }
    }
  }
  &__text {
    padding-top: 64px;
    border-bottom: 1px solid var(--color-border);
    padding-bottom: 24px;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: var(--color-dark-text);
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    }
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: var(--color-dark-text);
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  &__cookies {
    padding-top: 24px;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: var(--color-dark-text);
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    }
  }
}
@media (max-width: 960px) {
  .footer__text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: #020202;
    padding-top: 32px;
    padding-right: 15px;
    padding-left: 15px;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #020202;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    }
  }
  .footer__text-block {
    padding-bottom: 32px;
  }
  .footer__cookies {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: #020202;
    padding-right: 15px;
    padding-left: 15px;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    margin-bottom: 44px;
  }
  .footer__about {
    grid-template-columns: repeat(1, 1fr);
    margin-right: 15px;
    margin-left: 15px;
  }
  .footer__header {
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 0;
    &__image {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex: 1;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid #eef1f3;
    }
    &__menu {
      margin-right: 15px;
      margin-left: 15px;
      flex-direction: column;
      > div {
        padding-bottom: 24px;
        padding-left: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
