.Mui-selected {
  color: #219d8b !important;
}

.MuiTabs-indicator {
  background-color: #219d8b !important;
}
.profile {
  .text-title {
    padding-bottom: 20px;
    padding-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
  .MuiBox-root {
    padding: 0 !important;
  }
}
.tab-request {
  &__showcase {
    &__cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 24px;
      column-gap: 24px;
    }
    margin-bottom: 32px;
  }
}
.tab-rating {
  &__button {
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 170%;
      color: #020202;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    }
  }
  &__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div:first-child {
      min-width: 500px;
      margin: 30px;
    }
  }
  &__block-result {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 32px;
    &__card {
      background: #ffffff;
      border: 1px solid rgba(18, 18, 18, 0.05);
      box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      border-radius: 18px;
      padding: 32px;
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 124.18%;
        color: #273454;
        // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      }
      &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #273454;
        // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      }
    }
  }
}

.request-tab {
  &__block-not-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__images {
      img {
        width: 100%;
      }
    }
    &__buttons {
      padding-bottom: 101px;
      display: flex;
      flex-direction: row;
      .button__gray-theme {
        width: 210px;
        margin-right: 16px;
        text-align: center;
        justify-content: center;
      }
      .button__dark-theme {
        width: 210px;
        text-align: center;
        justify-content: center;
      }
    }
    &__text {
      display: column;
      flex-direction: row;
      align-items: center;
      max-width: 850px;
      &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #020202;
        // text-shadow: 0px 18px 52.8537px rgb(215 228 249 / 10%);
        text-align: center;
        span {
          font-style: italic;
          color: rgba(2, 2, 2, 0.5);
        }
        margin-bottom: 24px;
      }
      &__title {
        padding-bottom: 17px;
        img {
          padding-right: 5px;
        }
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        justify-content: center;
        align-items: center;
        display: flex;
        line-height: 124.18%;
        color: #020202;
        // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      }
    }
  }
}

.tab-profile {
  .text-title {
    padding-top: 32px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
  &__block {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid rgba(18, 18, 18, 0.05);
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.25);
    border-radius: 18px;
    padding: 24px;
    margin-bottom: 32px;
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 124.18%;
      display: flex;
      align-items: center;
      color: #020202;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid #f3f3f3;
    }
    &__cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 48px;
      row-gap: 48px;
      &__card {
        width: 100%;
        &__value {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: rgba(2, 2, 2, 0.75);
          border: 1px solid rgba(18, 18, 18, 0.05);
          box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
          border-radius: 70px;
          padding: 16px;
          width: calc(100% - 32px);
        }
        &__label {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: rgba(2, 2, 2, 0.75);
        }
      }
    }
  }
}
.tab-verification {
  &__card {
    border: 1px solid rgba(18, 18, 18, 0.05);
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    border-radius: 18px;
    padding: 32px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #273454;
      // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    }
  }
  &__block {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 15px;
    margin-bottom: 32px;
  }
}
.tab-complaint {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  &__send-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 124.18%;
      color: #020202;
      // text-shadow: 0px 18px 52.8537px rgb(215 228 249 / 10%);
      text-align: center;
    }
  }
  &__textarea {
    background: #ffffff;
    border: 1px solid rgba(18, 18, 18, 0.05);
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    border-radius: 16px;
    padding: 15px;
    resize: none;
    width: calc(100% - 32px);
    height: 300px;
  }
  &__label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: rgba(2, 2, 2, 0.75);
  }
  &__button {
    margin-top: 8px;
  }
}
.mobile-menu {
  display: none;
}
.block-tabs {
  border-bottom: 1px solid #eef1f3;
}

.tab-unsubscribe {
  margin-top: 24px;
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    margin-bottom: 24px;
  }
  &__text-mfo {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(2, 2, 2, 0.75);
    margin-bottom: 39px;
    span {
      margin-left: 10px;
      border: 1px solid rgba(18, 18, 18, 0.05);
      box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
      border-radius: 70px;
      padding: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #020202;
    }
  }
  &__button {
    margin-bottom: 32px;
  }
  .home__about__cards {
    row-gap: 40px;
    column-gap: 48px;
  }
}

@media (max-width: 992px) {
  .block-tabs {
    display: none !important;
  }
  .mobile-menu {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: -16px;
    border-top: 1px solid #eef1f3;
    &__menu {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
      > div {
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        color: #c0c0c0;
      }
    }
  }
  .profile {
    padding: 0 16px;
    width: calc(100% - 32px);
  }
  .tab-request {
    &__showcase__cards {
      grid-template-columns: repeat(1, 1fr);
    }
    &__showcase {
      margin-bottom: 22px;
    }
  }
  .tab-rating {
    &__block {
      width: 100%;
      flex-direction: column;
      > div:first-child {
        min-width: 100%;
        margin: 30px;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    &__button {
      margin-top: 0px;
      margin-bottom: 22px;
      span {
        display: none;
      }
    }
    &__block-result {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .tab-profile {
    &__block {
      &__cards {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 12px;
      }
    }
  }
  .tab-verification {
    &__block {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 12px;
    }
  }
  .request-tab {
    &__block-not-card {
      &__buttons {
        padding: 22px;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0px !important;
        .button__gray-theme {
          width: 100%;
          margin-right: 0;
          text-align: center;
          justify-content: center;
          margin-bottom: 22px;
        }
        .button__dark-theme {
          width: 100%;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
  .custom-modal {
    width: calc(100% - 100px);
  }
  .request-tab__block-not-card {
    align-items: center !important;
  }
}
.active-card {
  span {
    border-bottom: 3px solid #219d8b;
    padding-bottom: 3px;
    color: #219d8b;
  }
}

.not-active-card {
  span {
    color: red;
    padding-bottom: 3px;
    border-bottom: 3px solid red;
  }
}
